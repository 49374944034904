import ObjectEditor, { Json } from 'common/components/ObjectEditor';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { CompilationStatus } from 'common/types/compiler';
import {
  isErrorTransformResult,
  isOkTransformResult,
  TransformError,
  TransformResult
} from 'common/types/dsmapi';
import { FieldT, MetadataTemplate, MetadataType } from 'common/types/metadataTemplate';
import { intoMetadataComponents } from 'common/dsmapi/metadataTemplate';
import {
  ColumnRef,
  isColumnEqualIgnoringPosition,
  SoQLType,
  TableQualifier,
  TypedSoQLColumnRef
} from 'common/types/soql';
import { isString, isUndefined } from 'lodash';
import { FieldCompilation } from 'metadataTemplates/store';
import React from 'react';
import { Option } from 'ts-option';
import { Evaluatable } from './FieldEditor';
import './field-tester.scss';
import { InputTypeDropdownForged } from './InputTypeDropdown';
import { typedUniqColumnRefs } from '../util';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { ForgeIcon, ForgeInlineMessage, ForgeLinearProgress, ForgeTextField } from '@tylertech/forge-react';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export interface FieldTesterProps {
  field: FieldT;
  template: MetadataTemplate;
  qualifier: TableQualifier;
  inputValues: Evaluatable;
  evaluate: (values: Evaluatable) => Promise<void>;
  transformResult: TransformResult | null;
  updateInputType: (st: SoQLType) => void;
  enumeration: Option<string[]>;
  compilationResult: Option<FieldCompilation>;
}

// TODO extract all soql cell stuff into common, along with helpers like this.
// this is duplicated in dsmp
const cellErrorMessageToString = (errorCell: TransformError): string => {
  const message = errorCell.error.message;
  if (isString(message)) return message;
  return message.english;
};

const TransformResultAlertForge = ({
  output,
  inputValues
}: {
  output: TransformResult | null;
  inputValues: Evaluatable;
}) => {
  let theme;
  let message;
  let iconName;

if (output && inputValues[0]?.value) {
  if (isOkTransformResult(output)) {
    theme = 'success';
    message = JSON.stringify(output.ok, null, 2);
    iconName = 'check_circle';
  } else if (isErrorTransformResult(output)) {
    theme = 'danger';
    message = cellErrorMessageToString(output);
    iconName = 'error';
  }
}
  if (theme) {
    return (
      <ForgeInlineMessage theme={theme}>
        <ForgeIcon slot="icon" name={iconName} />
        <div slot="title">{t('validation_message_title')}</div>
        <div aria-label="evaluation-result">{message}</div>
      </ForgeInlineMessage>
    );
  } else {
    return null;
  }
};

interface State {
  isSpinning: boolean;
}

class FieldTester extends React.Component<FieldTesterProps, State> {
  state: State = {
    isSpinning: false
  };

  onChange = (currentValues: Evaluatable, columnRef: TypedSoQLColumnRef) => async (value: Json) => {
    const newInput = [
      ...currentValues.filter(
        (crefWithValue) => !isColumnEqualIgnoringPosition(crefWithValue.columnRef, columnRef)
      ),
      { columnRef, value }
    ];
    this.setState({ isSpinning: true });
    try {
      await this.props.evaluate(newInput);
    } catch (e) {
      console.error(e, newInput);
    }
    this.setState({ isSpinning: false });
  };

  getInputValues = (refs: TypedSoQLColumnRef[]) => {
    return refs.map((cref) => {
      const existingState = this.props.inputValues.find((istate) =>
        isColumnEqualIgnoringPosition(istate.columnRef, cref)
      );
      const value = existingState ? existingState.value : null;
      return { columnRef: cref, value };
    });
  };

  render() {
    const { compilationResult } = this.props;
    // We want to give the user the ability to put some text in some boxes
    // and get an output of the soql expression.
    // so we need to walk over the expression and find all the column references.
    // any reference, we will make an input box for
    const typedColumnRefs = typedUniqColumnRefs(this.props.template, this.props.field);
    const inputValues = this.getInputValues(typedColumnRefs);

    // gah react hooks are kind of annoying sometimes.
    // the initialState doesn't invalidate the persistent state
    // when it changes, so we can't just map over the column refs
    // and set the value of each to null, because when the component is replaced
    // with another one, inputState references the old columns.
    // so then we call evaluate with an insufficient input state.
    const { isSpinning } = this.state;
    const complexTypesEnabled = FeatureFlags.value('enable_complex_metadata_type_editing');

    type FieldCheckResults = {
      fieldTypeForbidsInputTypeChanges: boolean;
      parentField?: ColumnRef;
    };

    // Multiselect fields need to have an input type of JSON to work correctly.
    const { fieldTypeForbidsInputTypeChanges, parentField }: FieldCheckResults = (() => {
      return intoMetadataComponents(
        this.props.qualifier,
        this.props.field.field_name,
        this.props.field.parsed_expr,
        this.props.field.labels_options,
        this.props.template
      ).match<FieldCheckResults>({
        none: () => ({ fieldTypeForbidsInputTypeChanges: false }),
        some: (metadataComponents) => {
          const { type: fieldType } = metadataComponents;
          if (fieldType === MetadataType.multiSelect) {
            return { fieldTypeForbidsInputTypeChanges: true };
          }

          if (
            fieldType === MetadataType.dependentMultiSelect
          ) {
            return {
              fieldTypeForbidsInputTypeChanges: true,
              parentField: metadataComponents.parentField
            };
          }

          if (
            fieldType === MetadataType.dependentSelectWithSelectParent ||
            fieldType === MetadataType.dependentSelectWithMultiSelectParent
          ) {
            return {
              fieldTypeForbidsInputTypeChanges: false,
              parentField: metadataComponents.parentField
            };
          }

          return { fieldTypeForbidsInputTypeChanges: false };
        }
      });
    })();

    return (
      <div className="editor-preview">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          {typedColumnRefs.map((cref) => {
            const id = cref.qualifier ? `@${cref.qualifier}.${cref.value}` : cref.value;
            const key = `${cref.qualifier}_${cref.value}`;
            const value = inputValues.find((istate) =>
              isColumnEqualIgnoringPosition(istate.columnRef, cref)
            )?.value;
            const isParent = parentField && parentField.value === cref.value;

            const isSelf =
              cref.value === this.props.field.field_name && cref.qualifier === this.props.qualifier;

            const isJsonField = cref.soql_type === SoQLType.SoQLJsonT;

            const label: string | undefined = (() => {
              if (isParent) {
                return isJsonField
                  ? t('enter_a_test_value_for_parent_field', cref)
                  : t('parent_field_label');
              }

              if (parentField && isSelf) {
                return isJsonField ? t('enter_a_test_value_for_child_field', cref) : t('child_field_label');
              }

              if (isJsonField) {
                return t('enter_a_test_value', cref);
              }
            })();

            const typeSwitcher = !fieldTypeForbidsInputTypeChanges &&
              complexTypesEnabled &&
              isSelf &&
              !this.props.field.is_builtin && (
                <div className="input-field-type-switcher">
                  <InputTypeDropdownForged
                    key="type-switcher"
                    onSelection={this.props.updateInputType}
                    type={cref.soql_type}
                    disabled={
                      compilationResult.isDefined &&
                      compilationResult.get?.type !== CompilationStatus.Succeeded
                    }
                  />
                </div>
              );

            if (isJsonField) {
              return (
                <div key={key} className="input-field">
                  <div className="input-field-inner">
                    <label className="form-component-input-label" htmlFor={id}>
                      {label}
                    </label>
                    <ObjectEditor
                      key={key}
                      id={id}
                      thing={isUndefined(value) ? null : value}
                      onChange={this.onChange(inputValues, cref)}
                      isRestrictedForUser={false}
                    />
                  </div>
                  {typeSwitcher}
                </div>
              );
            }
            return (
              <div className="input-field-forged-outer" key={key}>
                <div className="input-field-forged-label">{label}</div>
                <div className="input-field-forged">
                  <ForgeTextField className="input-field-inner">
                    <input
                      disabled={compilationResult
                        .map((cr) => cr.type === CompilationStatus.Failed)
                        .getOrElseValue(false)}
                      data-testid={id}
                      value={isString(value) ? value : ''}
                      type="text"
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        this.onChange(inputValues, cref)(e.currentTarget.value)
                      }
                      id="validation-text-input"
                      placeholder={t('field_value')}
                      forge-dialog-focus="true"
                    />
                    <label htmlFor="validation-text-input" slot="label">
                      {cref.value}
                    </label>
                  </ForgeTextField>
                  {typeSwitcher}
                </div>
              </div>
            );
          })}
          {isSpinning && <ForgeLinearProgress data-testid="loader" />}
          {
            <TransformResultAlertForge
              output={this.props.transformResult}
              inputValues={this.props.inputValues}
            />
          }
        </form>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(FieldTester); // eslint-disable-line new-cap
